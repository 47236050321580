import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-nav',
  templateUrl: './section-nav.component.html',
  styleUrls: ['./section-nav.component.scss']
})
export class SectionNavComponent implements OnInit {
  @Input() active = 'home';
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openLegal() {
    this.router.navigate(['legal']);
  }
  openHome() {
    this.router.navigate(['home']);
  }
  openPro() {
    this.router.navigate(['pro']);
  }
  openSupport() {
    this.router.navigate(['support']);
  }
}

<style>
    html,
    body,
    header,
    .view {
        height: 100%;
    }
    
    @media (max-width: 740px) {
        html,
        body,
        header,
        .view {
            height: 1000px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 850px) {
        html,
        body,
        header,
        .view {
            height: 600px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>

<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark pink ie-nav fixed-top" [containerInside]="false">
    <mdb-navbar-brand>
        <a class="logo navbar-brand" href="#"><strong>ICOMUNITY</strong></a>
    </mdb-navbar-brand>
    <links>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item waves-light" mdbWavesEffect [class.active]="active === 'home'" (click)="openHome()">
                <a class="nav-link">
                    Inicio 
                    <span class="sr-only" *ngIf="active === 'home'">(current)</span>
                </a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect [class.active]="active === 'pro'" (click)="openPro()">
                <a class="nav-link">
                    Me interesa
                    <span class="sr-only" *ngIf="active === 'pro'">(current)</span>
                </a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect [class.active]="active === 'legal'" (click)="openLegal()">
                <a class="nav-link">
                    Legal 
                    <span class="sr-only" *ngIf="active === 'legal'">(current)</span>
                </a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect [class.active]="active === 'support'" (click)="openSupport()">
                <a class="nav-link">
                    Soporte 
                    <span class="sr-only" *ngIf="active === 'legal'">(current)</span>
                </a>
            </li>
        </ul>
    </links>
</mdb-navbar>
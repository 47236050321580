<style>
    html,
    body {
        height: 100%;
    }
    
    .view,
    header {
        height: 200px;
    }
    
    @media (max-width: 740px) {
        html,
        body {
            height: 1000px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 850px) {
        html,
        body {
            height: 600px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>
<div class="container my-5 z-depth-1">
    <section class="dark-grey-text p-md-3 mx-md-5">
        <h2 class="text-center  mb-4 pb-2 cp-title-teal">Confián en ICOMUNITY</h2>
        <div class="row">
            <div class="col-md-4" *ngFor="let card of cards; let cardIndex = index">
                <mdb-card class="mb-3 my-1 card-client">
                    <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    <mdb-card-body>
                        <mdb-card-title>
                            <h6>{{card.title}}</h6>
                        </mdb-card-title>
                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>

    </section>

</div>

<div class="container my-5 z-depth-1">
    <!--Section: Content-->

    <section class="dark-grey-text p-md-3 mx-md-5">

        <div class="row pr-lg-5">
            <div class="col-md-7 mb-4">

                <div class="view">
                    <img src="assets/img/ic-web-login.png" class="img-fluid" alt="smaple image">

                </div>
            </div>
            <div class="col-md-5 d-flex align-items-center">
                <div>

                    <h3 class="font-weight-bold mb-2">¿ Te interesa ?</h3>

                    <p>Tenemos ofertas para los pequeños y los grandes <br> Mira a ver si te pueden interesar <br>

                    </p>
                    <button mdbBtn type="button" color="info" rounded="true" outline="true" mdbWavesEffect routerLink="/pro">Me interesa</button>

                </div>
            </div>
        </div>

    </section>
    <!--Section: Content-->


</div>
import { Injectable } from '@angular/core';
import {  Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class CrudService {

    constructor(
      private http: HttpClient,
    ) { }
    sendContact(email: string, name: string, message: string): Observable<any> {
      return this.doPostRequest(`${environment.migrationApiUrl}/migration/web-contact`, {email, name, message});
    }
    sendSupport(email: string, name: string, message: string): Observable<any> {
      return this.doPostRequest(`${environment.apiUrl}/blog/send-support`, {email, name, message});
    }
    sendPricingInterest(email: string, name: string, message: string): Observable<any> {
      return this.doPostRequest(`${environment.migrationApiUrl}/migration/web-contact`, {email, name, message});
    }


    doPostRequest(url: string, body: any): Observable<any> {
      return this.http.post<any>(url, body).pipe(
          map( response => {
            return response;
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
  }
  doGetRequest(url: string, params: any): Observable<any> {
      return this.http.get<any>(url, params).pipe(
          map( response => {
            return response;
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
  }
  }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgwWowModule } from 'ngx-wow';
import { SectionFeaturesComponent } from './components/section-features/section-features.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SectionIntroComponent } from './components/section-intro/section-intro.component';
import { SectionFooterComponent } from './components/section-footer/section-footer.component';
import { SectionContactComponent } from './components/section-contact/section-contact.component';
import { HomeComponent } from './pages/home/home.component';
import { SectionProComponent } from './components/section-pro/section-pro.component';
import { LegalComponent } from './pages/legal/legal.component';
import { ProComponent } from './pages/pro/pro.component';
import { SectionNavComponent } from './components/section-nav/section-nav.component';
import { SectionStoresComponent } from './components/section-stores/section-stores.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { CrudService } from './common/crud.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SupportComponent } from './pages/support/support.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SectionFeaturesComponent,
    SectionHeaderComponent,
    SectionIntroComponent,
    SectionFooterComponent,
    SectionContactComponent,
    SectionProComponent,
    LegalComponent,
    ProComponent,
    SupportComponent,
    SectionNavComponent,
    SectionStoresComponent
  ],
  imports: [
    AppRoutingModule,
    NgwWowModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'kiwi-mob-web'}),
    NgxGoogleAnalyticsModule.forRoot('UA-178531878-1'),
    MDBBootstrapModule.forRoot()
  ],
  providers: [CrudService],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="container my-5 p-5 z-depth-1">


    <!--Section: Content-->
    <section class="dark-grey-text">

        <!-- Section heading -->
        <h2 class="text-center mb-4 pb-2 cp-title-teal">UNA APP MÓVIL ELEGANTE</h2>
        <!-- Grid row -->
        <div class="row">
            <!-- Grid column -->
            <div class="col-md-4">

                <!-- Grid row -->
                <div class="row mb-10">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-exclamation-triangle deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Gestión de incidencias </h5>
                        <p class="grey-text">
                            Cuando un usuario reporta <b>una incidencias</b> en su comunidad, nuestro sistema notifica esta incidencia al administrador y al resto de la comunidad.
                            <br>Cada vez que el administrador actualiza el estado de una incidencia, se notifica a todos los usuarios de la comunidad la actualización de estado.
                            <br>De esta forma mantenemos al día a los usuarios.
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Grid row -->
                <div class="row mb-10">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-bell deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Notificaciones en tiempo real</h5>
                        <p class="grey-text">
                            Los inquilinos y propietarios están <b>notificados en tiempo real</b> de todas las actualizaciones de estados de las incidencias de su comunidad <br> y también reciben las notificaciones de nuevas noticias y de nuevos documentos de su comunidad.
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Grid row -->
                <div class="row mb-md-0">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-calendar-alt deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Gestión de reservas</h5>
                        <p class="grey-text mb-md-0">
                            Los inquilinos y propietarios tienen acceso a nuestro sistema de reservas (con o sin pago) y pueden reservar tanto el Local de la comunidad como las pistas de Padel y de Tenis  de la comunidad. <b>todo dentro de nuestra App en un solo click</b>.
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 text-center">
                <img class="img-fluid" src="assets/img/mobile2.png" alt="Sample image">
                <img class="img-fluid" src="assets/img/mobile3.png" alt="Sample image">
            </div>

            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4">

                <!-- Grid row -->
                <div class="row mb-10">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-users deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Gestión de aforos</h5>
                        <p class="grey-text">
                            El administrador tiene la posibilidad de cambiar los horarios y aforos de cada una de sus comunidades. <br>Los inquilinos y propietarios podrán reservar franjas horarias según el aforo programado
                            por el administrador.
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->

                <!-- Grid row -->
                <div class="row mb-10">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-tree deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Medio ambiante</h5>
                        <p class="grey-text">Tramitando a través de nuestro sistema, fomenta una reducción de la cantidad de llamadas, de papeles y de desplazamientos
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->
                <!-- Grid row -->
                <div class="row mb-10">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-archive deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Gestión de documentos</h5>
                        <p class="grey-text mb-0">Nuestro <b>Panel Web</b> permite una gestión avanzada de los documentos de su comunidad, puede enviar distintos tipos de documentos a uno o a multiples usuarios o a una comunidad entera directamente. </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->
                <div class="row">

                    <!-- Grid column -->
                    <div class="col-2">
                        <i class="fas fa-2x fa-database deep-purple-text"></i>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-10">
                        <h5 class="font-weight-bold mb-3">Procesa los datos</h5>
                        <p class="grey-text mb-0">Nuestro Panel de administración incluye vistas estadisticas de sus comunidades y exportación de informes
                            <br> Bajo solicitud, realizamos integraciones para la exportación de datos hacía su sistema de facturación.
                        </p>
                    </div>
                    <!-- Grid column -->

                </div>
                <!-- Grid row -->

            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </section>
    <!--Section: Content-->


</div>
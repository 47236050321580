<div class="container z-depth-1 my-5 px-0">

    <!--Section: Content-->
    <section class="my-md-5" style="background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/People/12-col/img%20(97).jpg'); background-size: cover; background-position: center center;">

        <div class="rgba-black-strong rounded p-5">

            <!-- Section heading -->
            <h3 class="text-center font-weight-bold text-white mt-3 mb-5">Contáctanos</h3>

            <div class="mx-md-5">

                <div class="row">
                    <div class="col-md-6 mb-4">

                        <div class="card">
                            <div class="card-body px-4">
                                <!-- Name -->
                                <div class="md-form md-outline mt-0">
                                    <input type="text" id="name" class="form-control" placeholder="Nombre" [(ngModel)]="form.name">
                                </div>
                                <!-- Email -->
                                <div class="md-form md-outline">
                                    <input type="text" id="email" class="form-control" placeholder="Email" [(ngModel)]="form.email">
                                </div>
                                <!-- Message -->
                                <div class="md-form md-outline">
                                    <textarea id="message" class="md-textarea form-control" rows="3" placeholder="Mensaje" [(ngModel)]="form.message"></textarea>
                                </div>

                                <button (click)="submitEmail()" class="btn btn-primary btn-md btn-block ml-0 mb-0">Enviar</button>

                            </div>
                        </div>

                    </div>
                    <div class="col-md-5 offset-md-1 mt-md-4 mb-4 white-text">

                        <h5 class="font-weight-bold">Le contestaremos</h5>
                        <p class="mb-0">Gracias por ponerse en contacto con nosotros</p>
                        <p class="msg-success" *ngIf="contactSuccess">Mensaje correctamente enviado</p>
                    </div>
                </div>

            </div>

        </div>

    </section>
    <!--Section: Content-->


</div>
<style>
    html,
    body {
        height: 100%;
    }
    
    .view,
    header {
        height: 200px;
    }
    
    @media (max-width: 740px) {
        html,
        body {
            height: 1000px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 850px) {
        html,
        body {
            height: 600px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>
<div class="container my-5 py-5 z-depth-1">


    <!--Section: Content-->
    <section class="dark-grey-text text-center">

        <h3 class="font-weight-bold pt-4 mb-4">Disponible en las stores</h3>
        <div class="row mx-lg-5 mx-md-3">
            <div class="col-md-6  ">
                <a href="https://play.google.com/store/apps/details?id=developerteam.icomunity" target="_blank">
                    <div class="view  cp-container-image">
                        <img class="img-fluid cp-image-b" src="assets/img/playstore.png" alt="Play store image">
                    </div>
                </a>
            </div>
            <div class="col-md-6 ">
                <a href="https://apps.apple.com/es/app/icomunity/id1325772871" target="_blank">
                    <div class="view  cp-container-image">
                        <img class="img-fluid cp-image-b" src="assets/img/appstore.png" alt="App store image">
                    </div>
                </a>
            </div>
        </div>

    </section>
    <!--Section: Content-->


</div>
<style>
  html,
  body {
    height: 100%;
  }

  .view,
  header {
    height: 200px;
  }

  @media (max-width: 740px) {
    html,
    body {
      height: 1000px;
    }
  }

  @media (min-width: 800px) and (max-width: 850px) {
    html,
    body {
      height: 600px;
    }
  }

  .btn .fa {
    margin-left: 3px;
  }

  .top-nav-collapse {
    background-color: #424f95 !important;
  }

  .navbar:not(.top-nav-collapse) {
    background: transparent !important;
  }

  @media (max-width: 991px) {
    .navbar:not(.top-nav-collapse) {
      background: #424f95 !important;
    }
  }

  .btn-white {
    color: black !important;
  }

  h6 {
    line-height: 1.7;
  }

  .rgba-gradient {
    background: -moz-linear-gradient(
      45deg,
      rgba(42, 27, 161, 0.7),
      rgba(29, 210, 177, 0.7) 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(42, 27, 161, 0.7),
      rgba(29, 210, 177, 0.7) 100%
    );
    background: -webkit-gradient(
      linear,
      45deg,
      from(rgba(42, 27, 161, 0.7)),
      to(rgba(29, 210, 177, 0.7))
    );
    background: -o-linear-gradient(
      45deg,
      rgba(42, 27, 161, 0.7),
      rgba(29, 210, 177, 0.7) 100%
    );
    background: linear-gradient(
      to 45deg,
      rgba(42, 27, 161, 0.7),
      rgba(29, 210, 177, 0.7) 100%
    );
  }
</style>
<!-- Main navigation -->
<header>
  <app-section-nav [active]="'legal'"></app-section-nav>
  <!-- Full Page Intro -->
  <div
    class="view"
    style="
      background-image: url('assets/img/bg-crop.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    "
  >
    <!-- Mask & flexbox options-->
    <div
      class="mask rgba-gradient d-flex justify-content-center align-items-center"
    >
      <!-- Content -->
      <h1 class="h1-responsive white-text font-weight-bold mt-sm-5">Legal</h1>
    </div>
  </div>
</header>
<section class="dark-grey-text">
  <div class="container">
    <!--Grid row-->
    <div class="row">
      <div class="container my-5 z-depth-1 bg-white">
        <!--Section: Content-->
        <section class="dark-grey-text page-section">
          <div class="row">
            <div class="col-md-10 d-flex align-items-center">
              <div>
                <h3 class="font-weight-bold mb-4">CONDICIONES DE USO</h3>

                <p><b> INFORMACIÓN RELEVANTE</b></p>
                <p>
                  Es requisito necesario para la utilizacion de esta aplicacion,
                  que lea y acepte los siguientes Términos y Condiciones que a
                  continuación se redactan.
                </p>

                <p>
                  El uso de la aplicación implicara que ha aceptado las
                  condiciones de uso. <br />
                  El usuario no podra cambiar sus datos personales una vez
                  introducidos, salvo que borre e instale la aplicación de
                  nuevo.<br />
                </p>
                <p><b> LICENCIA</b></p>
                <p>
                  Developerteam registra cada usuario y lo guarda en la base de
                  datos de cada comunidad. El administrador de cada comunidad
                  podrá tener acceso a dicha base de datos si así lo requiere.
                </p>
                <p><b> PROPIEDAD</b></p>
                <p>
                  Usted no puede declarar propiedad intelectual o exclusiva a
                  nuestra aplicación, modificada o sin modificar. En caso de que
                  no se especifique lo contrario, nuestra aplicación no se hace
                  responsable del funcionamiento de los servicios que tiene
                  contratado o recomendado en su comunidad ni ofrece ningún tipo
                  de garantía, expresa o implícita. En ningún caso esta compañía
                  será responsables de ningún daño incluyendo, daños directos,
                  indirectos, especiales, fortuitos o consecuentes o resultantes
                  de los servicios que se ofrecen o se demandan.
                </p>
                <p><b> PRIVACIDAD</b></p>
                <p>
                  Esta aplicacion Icomunity garantiza que la información
                  personal que usted envía cuenta con la seguridad necesaria.
                  Los datos ingresados por usuario o en el caso de requerir
                  datos, podran ser compartidos con gremios que tienen
                  contratados y/o recomendados y con su administrador de la
                  comunidad. La suscripción a boletines de correos electrónicos
                  publicitarios es voluntaria y podría ser seleccionada al
                  momento de crear su cuenta.
                </p>
                <p>
                  ICOMUNITY TEAM. reserva los derechos de cambiar o de modificar
                  estos términos sin previo aviso.
                </p>

                <h3 class="font-weight-bold mb-4">
                  Política de privacidad de ICOMUNITY
                </h3>

                <p>
                  Para recibir la información sobre sus Datos Personales, la
                  finalidad y las partes con las que se comparte, contacten con
                  el Titular.
                </p>

                <h4>Titular y Responsable del tratamiento de los Datos</h4>

                <p>
                  Steve Stan BEREKOUTOU, C/ Cabezo Buenavista 13, 50007 Zaragoza
                  <b>Correo electrónico de contacto del Titular:</b>
                  icomunity.soporte@gmail.com
                </p>
                <h4>Clases de Datos recogidos</h4>
                <p>
                  El Titular no proporciona una lista de categorías de Datos
                  Personales recogidos.
                </p>
                <p>
                  La información completa referente a cada categoría de Datos
                  Personales que se recogen se proporciona en las secciones de
                  la presente política de privacidad dedicadas a tal fin o
                  mediante textos explicativos específicos que se muestran antes
                  de la recogida de dichos Datos. Los Datos Personales podrán
                  ser proporcionados libremente por el Usuario o, en caso de los
                  Datos de Uso, serán recogidos automáticamente cuando se
                  utilice esta Aplicación. Salvo que se indique lo contrario,
                  todos los Datos solicitados por esta Aplicación son
                  obligatorios y la negativa a proporcionarlos podrá
                  imposibilitar que esta Aplicación pueda proceder a la
                  prestación de sus servicios. En los casos en los que esta
                  Aplicación indique específicamente que ciertos Datos no son
                  obligatorios, los Usuarios serán libres de no comunicar tales
                  Datos sin que esto tenga consecuencia alguna sobre la
                  disponibilidad o el funcionamiento del Servicio. Los Usuarios
                  que tengan dudas sobre qué Datos son obligatorios pueden
                  contactar con el Titular. El uso de Cookies - o de otras
                  herramientas de seguimiento - por parte de esta Aplicación o
                  por los titulares de servicios de terceros utilizados por esta
                  Aplicación tiene como finalidad la prestación del Servicio
                  solicitado por el Usuario, además de cualesquiera otras
                  finalidades que se describan en el presente documento y en la
                  Política de Cookies.
                </p>
                <p>
                  El Usuario asume la responsabilidad respecto de los Datos
                  Personales de terceros que se obtengan, publiquen o compartan
                  a través de esta Aplicación.
                </p>
                <h4>
                  Modalidad y lugar del tratamiento de los Datos recogidos
                </h4>
                <p>
                  <b>Modalidades de Tratamiento</b> <br />
                  El Titular tratará los Datos de los Usuarios de manera
                  adecuada y adoptará las medidas de seguridad apropiadas para
                  impedir el acceso, la revelación, alteración o destrucción no
                  autorizados de los Datos. El tratamiento de Datos se realizará
                  mediante ordenadores y/o herramientas informáticas, siguiendo
                  procedimientos y modalidades organizativas estrictamente
                  relacionadas con las finalidades señaladas. Además del
                  Titular, en algunos casos podrán acceder a los Datos ciertas
                  categorías de personas autorizadas, relacionadas con el
                  funcionamiento de esta Aplicación (administración, ventas,
                  marketing, departamento jurídico y de administración de
                  sistemas) o contratistas externos que presten servicios al
                  Titular (tales como proveedores externos de servicios
                  técnicos, empresas de mensajería, empresas de hosting,
                  empresas de informática, agencias de comunicación) que serán
                  nombrados por el Titular como Encargados del Tratamiento, si
                  fuera necesario. Se podrá solicitar al Titular en cualquier
                  momento una lista actualizada de dichas personas.
                </p>
                <p>
                  <b>Lugar</b><br />
                  Los Datos se tratan en las oficinas del Titular, así como en
                  cualquier otro lugar en el que se encuentren situadas las
                  partes implicadas en dicho proceso de tratamiento. Dependiendo
                  de la localización de los Usuarios, las transferencias de
                  Datos pueden implicar la transferencia de los Datos de los
                  Usuarios a otro país diferente al suyo propio. Para más
                  información sobre el lugar de tratamiento de dichos Datos
                  transferidos, los Usuarios podrán consultar la sección que
                  contiene los detalles sobre el tratamiento de los Datos
                  Personales.
                </p>
                <p>
                  <b>Período de conservación</b><br />
                  Salvo que se indique lo contrario en el presente documento,
                  los Datos Personales serán tratados y conservados durante el
                  tiempo necesario y para la finalidad por la que han sido
                  recogidos y podrán conservarse durante más tiempo debido a una
                  obligación legal pertinente o sobre la base del consentimiento
                  de los Usuarios.
                </p>
                <h4>Política de Cookies</h4>
                <p>
                  Esta Aplicación utiliza Rastreadores. Para obtener más
                  información, los Usuarios pueden consultar la Política de
                  Cookies.
                </p>
                <h4>Más información para los usuarios</h4>
                <p>
                  <b>Base jurídica del Tratamiento</b> <br />
                  El Titular podrá tratar los Datos Personales del Usuario, si
                  se cumple una de las siguientes condiciones:
                </p>
                <p>
                  Cuando los Usuarios hayan dado su consentimiento para una o
                  más finalidades específicas. Aviso: Al amparo de varias
                  legislaciones diferentes, el Titular podrá estar autorizado a
                  tratar los Datos Personales hasta que el Usuario se oponga a
                  ello (“opción de no participación”), sin necesidad de
                  consentimiento o de cualquier otra base jurídica. Sin embargo,
                  esto no será aplicable cuando el tratamiento de los Datos
                  Personales esté sujeto a la normativa europea en materia de
                  protección de Datos Personales; Cuando la obtención de Datos
                  sea necesaria para el cumplimiento de un contrato con el
                  Usuario y/o cualquier otra obligación precontractual del
                  mismo; Cuando el tratamiento sea necesario para el
                  cumplimiento de una obligación legal de obligado cumplimiento
                  por parte del Usuario; Cuando el tratamiento esté relacionado
                  con una tarea ejecutada en interés público o en el ejercicio
                  de competencias oficiales otorgadas al Titular; Cuando el
                  tratamiento sea necesario con el fin de un interés legítimo
                  perseguido por el Titular o un tercero. En cualquier caso, el
                  Titular está a su disposición para definir las bases jurídicas
                  específicas que se aplican al tratamiento y en particular, si
                  la obtención de los Datos Personales es un requisito
                  contractual o estatutario o un requisito necesario para
                  formalizar un contrato.
                </p>
                <p>
                  <b>Más información sobre el tiempo de retención</b><br />
                  Salvo que se indique lo contrario en el presente documento,
                  los Datos Personales serán tratados y conservados durante el
                  tiempo necesario y para la finalidad por la que han sido
                  recogidos y podrán conservarse durante más tiempo debido a una
                  obligación legal pertinente o sobre la base del consentimiento
                  de los Usuarios.
                </p>
                <p>
                  Por lo tanto: <br />
                  - Los Datos Personales recogidos para la formalización de un
                  contrato entre el Titular y el Usuario deberán conservarse
                  como tales hasta en tanto dicho contrato se haya formalizado
                  por completo. <br />
                  - Los Datos Personales recogidos en legítimo interés del
                  Titular deberán conservarse durante el tiempo necesario para
                  cumplir con dicha finalidad. Los Usuarios pueden encontrar
                  información específica relacionada con el interés legítimo del
                  Titular consultando las secciones relevantes del presente
                  documento o contactando con el Titular. <br />
                  <br />
                  El Titular podrá conservar los Datos Personales durante un
                  periodo adicional cuando el Usuario preste su consentimiento a
                  tal tratamiento, siempre que dicho consentimiento siga
                  vigente. Además, el Titular estará obligado a conservar Datos
                  Personales durante un periodo adicional siempre que se precise
                  para el cumplimiento de una obligación legal o por orden que
                  proceda de la autoridad. Una vez terminado el período de
                  conservación, los Datos Personales deberán eliminarse. Por lo
                  tanto, los derechos de acceso, modificación, rectificación y
                  portabilidad de datos no podrán ejercerse una vez haya
                  expirado dicho periodo.
                </p>
                <p>
                  <b>Los derechos de los Usuarios</b> <br />
                  Los Usuarios podrán ejercer ciertos derechos con respecto al
                  tratamiento de Datos por parte del Titular.
                </p>
                <p>
                  En particular, los Usuarios tienen derecho a hacer lo
                  siguiente, en la medida en que lo permita la ley: <br />
                  - <b>Retirar su consentimiento en cualquier momento.</b>
                  <br />
                  Los Usuarios tienen derecho a retirar su consentimiento cuando
                  lo hubieran concedido con anterioridad para el tratamiento de
                  sus Datos Personales. <br />
                  - <b>Objeción al tratamiento de sus Datos. </b> <br />
                  Los Usuarios tienen derecho a oponerse al tratamiento de sus
                  Datos si dicho tratamiento se lleva a cabo con arreglo a una
                  base jurídica distinta del consentimiento. <br />
                  - <b>Acceso a sus Datos. </b> <br />
                  Los Usuarios tienen derecho a conocer si sus Datos serán
                  tratados por el Titular, a obtener información sobre ciertos
                  aspectos del tratamiento, además de obtener una copia de los
                  Datos objeto del tratamiento. <br />
                  - <b>Verificar y solicitar la modificación.</b> <br />
                  Los Usuarios tienen derecho a verificar la exactitud de sus
                  Datos y solicitar que los mismos se actualicen o corrijan.
                  <br />
                  - <b>Limitar el tratamiento de sus Datos. </b> <br />
                  Los Usuarios tienen derecho a limitar el tratamiento de sus
                  Datos. En ese supuesto, el Titular solo tratará sus Datos con
                  la finalidad de almacenarlos. <br />
                  - <b>Borrar o eliminar los Datos Personales. </b><br />
                  Los Usuarios tienen derecho a obtener la supresión de sus
                  Datos por parte del Titular. <br />
                  -
                  <b>Recibir sus Datos y transferirlos a otro responsable. </b>
                  <br />
                  Los Usuarios tienen derecho a recibir sus Datos en un formato
                  estructurado, de uso común y lectura mecánica y, si fuera
                  técnicamente posible, a que se transmitan los mismos a otro
                  responsable sin ningún impedimento. <br />
                  -<b>Poner una reclamación. </b> <br />
                  Los Usuarios tienen derecho a poner una reclamación ante la
                  autoridad competente en materia de protección de datos de
                  carácter personal. Los Usuarios también tendrán derecho a
                  conocer las bases legales de las transferencias de Datos al
                  extranjero, incluido a cualquier organización internacional
                  que se rija por el Derecho Internacional Público o que esté
                  formada por dos o más países, como la ONU, y a conocer las
                  medidas de seguridad tomadas por el Titular para salvaguardar
                  sus Datos.
                </p>
                <p>
                  <b>Detalles sobre el derecho de oposición al tratamiento</b
                  ><br />
                  Cuando el tratamiento de los Datos Personales sea de interés
                  público, en el ejercicio de competencias oficiales otorgadas
                  al Titular o con motivo de un interés legítimo del Titular,
                  los Usuarios podrán oponerse a dicho tratamiento explicando un
                  motivo con relación a su situación particular para justificar
                  su objeción. Los Usuarios deben saber, sin embargo, que en
                  caso de que sus Datos Personales sean tratados con fines de
                  marketing directo, pueden oponerse en cualquier momento a tal
                  tratamiento, de forma gratuita y sin necesidad de
                  justificación. Cuanto el Usuario se oponga al tratamiento para
                  fines de marketing directo, los Datos Personales no podrán
                  continuar siendo tratados para tales fines. Para saber si los
                  Datos Personales de los Usuarios están siendo tratados por el
                  Titular para fines de marketing directo, los Usuarios deberán
                  consultar las secciones relevantes del presente documento.
                </p>

                <p>
                  <b>Cómo ejercer estos derechos</b><br />
                  Cualquier solicitud para ejercer los derechos del Usuario
                  puede dirigirse al Titular a través de los datos de contacto
                  facilitados en el presente documento. Dichas solicitudes serán
                  tramitadas por el Titular sin coste alguno y el Titular
                  responderá a ellas tan pronto como le sea posible y siempre
                  dentro del plazo de un mes, proporcionando a los Usuarios la
                  información exigida por la ley. El Titular comunicará
                  cualquier rectificación o supresión de Datos Personales o
                  limitación del tratamiento, a cada destinatario, en su caso,
                  al que se le hayan comunicado los Datos Personales, salvo que
                  sea imposible o exija un esfuerzo desproporcionado. A
                  solicitud de los Usuarios, el Titular les informará sobre
                  dichos destinatarios. Información adicional sobre la recogida
                  de Datos y su tratamiento
                </p>

                <p>
                  <b> Defensa jurídica</b> <br />
                  Los Datos Personales del Usuario podrán ser utilizados para la
                  defensa jurídica del Titular ante un tribunal o en las fases
                  judiciales previas a un posible pleito derivado del uso
                  inapropiado de esta Aplicación o de los Servicios
                  relacionados. El Usuario declara conocer que el Titular puede
                  ser requerido por las autoridades públicas a fin de revelar
                  Datos Personales.
                </p>
                <p>
                  <b> Log del sistema y mantenimiento</b> <br />
                  Por motivos relativos al funcionamiento y mantenimiento, esta
                  Aplicación y cualquier otro servicio, proporcionado por
                  terceros, que se utilice, podrá recoger un registro del
                  sistema; es decir, archivos que registren la interacción con
                  esta Aplicación y que puedan contener Datos Personales, tales
                  como la dirección IP del Usuario.
                </p>
                <p>
                  <b>Información no contenida en esta política de privacidad </b
                  ><br />
                  Se podrá solicitar en cualquier momento información adicional
                  sobre la recogida y el tratamiento de los Datos Personales al
                  Titular. La información de contacto se indica al inicio del
                  presente documento.
                </p>
                <p>
                  <b>Modificación de la presente política de privacidad</b
                  ><br />
                  El Titular se reserva el derecho de modificar esta política de
                  privacidad en cualquier momento, notificándolo a los Usuarios
                  a través de esta página y, a ser posible, a través de esta
                  Aplicación y/o de ser técnica y legalmente posible notificando
                  directamente a los Usuarios, en caso de que el Titular cuente
                  con la información de contacto necesaria a tal fin. Se
                  recomienda encarecidamente que revisen esta página con
                  frecuencia, tomando como referencia la fecha de la última
                  actualización indicada al final de la página. En el caso de
                  que los cambios afectasen a las actividades de tratamiento
                  realizadas en base al consentimiento del Usuario, el Titular
                  deberá obtener, si fuera necesario, el nuevo consentimiento
                  del Usuario.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--Section: Content-->
      </div>
    </div>
  </div>
</section>
<app-section-footer></app-section-footer>

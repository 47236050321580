import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-pro',
  templateUrl: './section-pro.component.html',
  styleUrls: ['./section-pro.component.scss']
})
export class SectionProComponent implements OnInit {

  cards = [
    {
      title: 'IDEE GESTION 2010',
      img: 'assets/img/com_ven.jpg'
    },
    {
      title: 'CONSTRUCCIONES HIDRICAS DEL EBRO',
      img: 'assets/img/com_hidricas.jpg'
    },
    {
      title: 'METROFIN & ASOCIADOS',
      img: 'assets/img/com_metro.jpg'
    }
  ];
  slides: any = [[]];
  chunk(arr: any, chunkSize: any) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  ngOnInit() {
    this.slides = this.chunk(this.cards, 4);
  }
}

<div class="container my-5 p-5 z-depth-1">
  <!--Section: Content-->
  <section class="dark-grey-text">
    <!-- Section heading -->
    <h2 class="text-center mb-4 pb-2 cp-title-teal">
      NOS PRESENTAMOS EN 2 MINUTOS
    </h2>
    <iframe
      width="100%"
      height="400"
      src="https://www.youtube.com/embed/ghJ3gkj0D1A"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </section>
</div>
<div class="container my-5 p-5 z-depth-1">
  <!--Section: Content-->
  <section class="dark-grey-text">
    <!-- Section heading -->
    <h2 class="text-center mb-4 pb-2 cp-title-teal">LO QUE OFRECEMOS</h2>
    <!-- Section description -->
    <div class="row mb-3">
      <!-- Grid column -->
      <div class="col-1">
        <i class="fas fa-desktop fa-lg indigo-text"></i>
      </div>
      <div class="col-xl-10 col-md-11 col-10">
        <p class="lead black-text mx-auto mb-5">
          <b>Un panel de administración</b> para profesionales con
          funcionalidades avanzadas que le permitirán gestionar las necesidades
          de inquilinos y propietarios de forma eficaz y ágil, maximizando sus
          recursos ahorrando tiempo y dinero.
         <br>Podrá organizar la gestion administrativa de todas sus comunidades en un solo lugar y con un solo click
        </p>
      </div>
    </div>
    <div class="row mb-3">
      <!-- Grid column -->
      <div class="col-1">
        <i class="fas fa-mobile fa-lg indigo-text"></i>
      </div>
      <div class="col-xl-10 col-md-11 col-10">
        <p class="lead black-text mx-auto mb-5">
          <b>Una App móvil</b> agíl y facil de usar, para que tus inquilinos y
          propietarios gestionen cómodamente su día a día en su comunidad.
          <br>Sus usuarios podrán llevar la gestion administrativa dentro de su comunidad en un solo lugar y con un solo click
        </p>
      </div>
    </div>
    <h2 class="text-center mb-4 cp-title-teal">
      UN PANEL DE ADMINISTRACIÓN MULTI FUNCIONAL
    </h2>
    <!-- Grid row -->
    <div class="row">
      <section class="dark-grey-text"></section>
      <!-- Grid column -->
      <div class="col-lg-5 text-center text-lg-left mt-sm-5">
        <img
          class="img-fluid"
          src="assets/img/panel_2.png"
          alt="Sample image"
        />
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-lg-7 cp-flex-vertical  mt-sm-5">
        <div class="row mb-3">
            <!-- Grid column -->
            <div class="col-1">
              <i class="fas fa-object-group fa-lg indigo-text"></i>
            </div>
            <!-- Grid column -->
            <div class="col-xl-10 col-md-11 col-10">
              <h5 class="font-weight-bold mb-3">Centraliza</h5>
              <p class="grey-text">
                Desde el panel wel de administración , accede  y visualiza todas tus comunidades en un único
              sitio. El panel esta diseñado para todo tipo de usuario y no requiere de conocimientos previos para ser utilizado.
              </p>
            </div>
          </div>
        <div class="row mb-3">
          <!-- Grid column -->
          <div class="col-1">
            <i class="fas fa-building fa-lg indigo-text"></i>
          </div>
          <!-- Grid column -->
          <div class="col-xl-10 col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">Controla</h5>
            <p class="grey-text">
              Visualiza todo lo que ocurre en tus comunidades en tiempo real:
              Las incidencias, las reservas de las areas comunes, las
              solicitudes de tus propietarios.
            </p>
          </div>
          
        </div>
        <div class="row mb-3">
            <!-- Grid column -->
            <div class="col-1">
              <i class="fas fa-building fa-lg indigo-text"></i>
            </div>
            <!-- Grid column -->
            <div class="col-xl-10 col-md-11 col-10">
              <h5 class="font-weight-bold mb-3">Informa</h5>
              <p class="grey-text">
                Desde el panel de administración, notifica cualquiera información
              via notificaciones pushes a tus usuarios. También podrán acceder al histórico de noticias de su comunidad desde la App móvil
              </p>
            </div>
            
          </div>
        
        <!-- Grid row -->

        <!-- Grid row -->
        <div class="row mb-3">
          <!-- Grid column -->
          <div class="col-1">
            <i class="fas fa-share fa-lg indigo-text"></i>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-xl-10 col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">Gestiona</h5>
            <p class="grey-text">
                Icomunity reinventa la tarea administrativa brindando comodidad, agilidad y transparencia en la gestión, haciendo mas simple tu  dia a dia y el de los vecinos de la comunidad.
                <br>Podrá gestionar las incidencias, las noticias, las reservas, los documentos, los teléfonos y servicios de la comunidad, también tendrá la posibilidad de generar y exportar informes.
            </p>
          </div>
          <!-- Grid column -->
        </div>
        <!--Grid row-->
      </div>
      <!--Grid column-->
    </div>
    <!-- Grid row -->
  </section>
  <!--Section: Content-->
</div>

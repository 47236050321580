import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../common/crud.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  form = {
    name: '',
    email: '',
    message: ''
  };
  contactSuccess = false;
  constructor(
    private crudService: CrudService,
  ) { }

  ngOnInit(): void {

  }
  reset() {
    this.form = {
      name: '',
      email: '',
      message: ''
    };
  }

  submitEmail() {
    try {
    if (this.form.name !== '' && this.form.email !== '' && this.form.message !== '') {
      this.crudService.sendSupport(this.form.email, this.form.name, this.form.message)
      .subscribe( response => {
        this.reset();
        this.contactSuccess = true;
        setTimeout(() => {
            this.contactSuccess = false;
        }, 5000);
      });
    } else {
      console.log('SEND CONTACT FORM INVALID -> ', this.form);
    }
    } catch (error ) {
      console.log('CRASH ERROR -> ', error);
    }
  }

}

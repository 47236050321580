<style>
    html,
    body {
        height: 100%;
    }
    
    .view,
    header {
        height: 200px;
    }
    
    @media (max-width: 740px) {
        html,
        body {
            height: 1000px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 850px) {
        html,
        body {
            height: 600px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>
<!-- Main navigation -->
<header>
    <app-section-nav [active]="'pro'"></app-section-nav>
    <!-- Full Page Intro -->
    <div class="view" style="background-image: url('assets/img/bg-crop.png'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
        <!-- Mask & flexbox options-->
        <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
            <!-- Content -->
            <h1 class="h1-responsive white-text font-weight-bold mt-sm-5">Me interesa</h1>
        </div>
    </div>
</header>

<!-- <section class="dark-grey-text">
    <div class="container">
        <div class="row">
            <div class="container demo-container pt-5 my-5 z-depth-1">
                <section class="p-md-3 mx-md-5">
                    <div class="demo10">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-8">
                                    <div class="pricingTable10">
                                        <div class="pricingTable-header">
                                            <h3 class="heading">Standard</h3>
                                            <span class="price-value">
                                                    <span class="currency">€</span> 59
                                            <span class="month">/mes</span>
                                            </span>
                                        </div>
                                        <div class="pricing-content">
                                            <ul class="list-unstyled my-5 text-small text-left">
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Hasta 10 portales </li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de incidencias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de noticias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de usuarios</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Telefonos utiles y de servicios</li>

                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Soporte tecnico 24/7 </li>
                                                <li class="mb-3 text-muted">
                                                    <i class="fa fa-times mr-2"></i>
                                                    <del>Reservas de Tenis y/o Padel</del>
                                                </li>
                                                <li class="mb-3 text-muted">
                                                    <i class="fa fa-times mr-2"></i>
                                                    <del>Gestión de aforos piscina y/o solarium</del>
                                                </li>
                                                <li class="mb-3 text-muted">
                                                    <i class="fa fa-times mr-2"></i>
                                                    <del>Exportación de informes</del>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-8">
                                    <div class="pricingTable10">
                                        <div class="pricingTable-header">
                                            <h3 class="heading">Business</h3>
                                            <span class="price-value">
                                                    <span class="currency">€</span> 89
                                            <span class="month">/mes</span>
                                            </span>
                                        </div>
                                        <div class="pricing-content">
                                            <ul class="list-unstyled my-5 text-small text-left">
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Hasta 25 portales </li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de incidencias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de noticias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de usuarios</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Telefonos utiles y de servicios</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Reservas de Tenis y/o Padel</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Soporte tecnico 24/7 </li>
                                                <li class="mb-3 text-muted">
                                                    <i class="fa fa-times mr-2"></i>
                                                    <del>Gestión de aforos piscina y/o solarium</del>
                                                </li>
                                                <li class="mb-3 text-muted">
                                                    <i class="fa fa-times mr-2"></i>
                                                    <del>Exportación de informes</del>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-8">
                                    <div class="pricingTable10">
                                        <div class="pricingTable-header">
                                            <h3 class="heading">Premium</h3>
                                            <span class="price-value">
                                                    <span class="currency">€</span> 129
                                            <span class="month">/mes</span>
                                            </span>
                                        </div>
                                        <div class="pricing-content">
                                            <ul class="list-unstyled my-5 text-small text-left">
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Hasta 50 portales </li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de incidencias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de noticias</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de usuarios</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Telefonos utiles y de servicios</li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Reservas de Tenis y/o Padel</li>

                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Gestión de aforos piscina y/o solarium </li>
                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Exportación de informes</li>

                                                <li class="mb-3">
                                                    <i class="fa fa-check mr-2 text-primary"></i> Soporte tecnico 24/7 </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section> -->

<div class="container my-5 z-depth-1">


    <!--Section: Content-->
    <section class="my-md-5" style="background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/People/12-col/img%20(97).jpg'); background-size: cover; background-position: center center;">

        <div class="rgba-black-strong rounded p-5">

            <!-- Section heading -->
            <h3 class="text-center font-weight-bold text-white mt-3 mb-5">Te contactamos</h3>

            <div class="mx-md-5">

                <div class="row">
                    <div class="col-md-6 mb-4">

                        <div class="card">
                            <div class="card-body px-4">
                                <!-- Name -->
                                <div class="md-form md-outline mt-0">
                                    <input type="text" id="name" class="form-control" placeholder="Nombre" [(ngModel)]="form.name">
                                </div>
                                <!-- Email -->
                                <div class="md-form md-outline">
                                    <input type="text" id="email" class="form-control" placeholder="Email" [(ngModel)]="form.email">
                                </div>
                                <!-- Message -->
                                <div class="md-form md-outline">
                                    <textarea id="message" class="md-textarea form-control" rows="3" placeholder="Telefono y franja horaria para contactarte" [(ngModel)]="form.message"></textarea>
                                </div>

                                <button (click)="submitEmail()" class="btn btn-primary btn-md btn-block ml-0 mb-0">Enviar</button>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 offset-md-1 mt-md-4 mb-4 white-text">
                        <h5 class="font-weight-bold">Te contactaremos en las proximas 24h</h5>
                        <p class="msg-success" *ngIf="contactSuccess">Mensaje correctamente enviado</p>
                    </div>
                </div>

            </div>

        </div>

    </section>


</div>
<app-section-footer></app-section-footer>
<style>
    html,
    body,
    header,
    .view {
        height: 1200px;
    }
    
    @media (max-width: 740px) {
        html,
        body,
        header,
        .view {
            height: 1600px;
        }
        .row {
            padding-top: 50px;
        }
    }
    @media (min-width: 800px) and (max-width: 900px) {
        html,
        body,
        header,
        .view {
            height: 1200px;
        }
        .row {
            padding-top: 50px;
        }
    }
    @media (min-width: 400px) and (max-width: 800px) {
        html,
        body,
        header,
        .view {
            height: 1600px;
        }
        .row {
            padding-top: 50px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>

<!-- Main navigation -->
<header>
    <app-section-nav [active]="'home'"></app-section-nav>
    <!-- Full Page Intro -->
    <div class="view" style="background-image: url('https://mdbootstrap.com/img/Photos/Others/architecture.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
        <!-- Mask & flexbox options-->
        <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
            <!-- Content -->
            <div class="container">
                <!--Grid row-->
                <div class="row">
                    <!--Grid column-->
                    <div class="col-md-6 white-text text-center text-md-left mt-xl-5 wow fadeInLeft kw-cp-header" data-wow-delay="0.3s">
                        <h4 class="h2-responsive mt-sm-5 cp-subtitle"> ICOMUNITY <br> GESTOR DIGITAL DE COMUNIDADES DE VECINOS</h4>
                        <hr class="hr-light">
                        <h4 class="h4-responsive  mb-3 cp-subtitle"> GESTIONA TODAS TUS COMUNIDADES DE VECINOS EN UN ÚNICO SITIO 24/7</h4>
                        <ul class="ul-responsive h5-responsive font-weight-bold mt-sm-2 cp-ul">
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label">INCIDENCIAS</p>
                                    <p class="cp-li-card-text">Gestiona las altas de incidencias, el seguimiento, las actualizaciones de estados y realiza exportación de informes</p>
                                </div>
                            </li>
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label">DOCUMENTOS</p>
                                    <p class="cp-li-card-text">Gestiona los documentos de tus comunidades desde tu panel de administración, asigna documentos a todos los usuarios de una comunidad o a un usuario en concreto.</p>
                                </div>
                            </li>
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label">NOTICIAS</p>
                                    <p class="cp-li-card-text">Avisa a los usuarios de tus comunidades sobre lo que occurre en su comunidad enviando noticias mediante notificaciones pushes </p>
                                </div>
                                
                            </li>
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label">RESERVAS</p>
                                    <p class="cp-li-card-text">Gestiona los horarios, los aforos y las reservas de  las areas comunes de tus comunidades : local, pista de tenis, pista de padel, piscinas, solarium </p>
                                </div>
                            </li>
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label"> DATOS ÚTILES</p>
                                    <p class="cp-li-card-text">Gestiona los teléfonos útiles de tus comunidades: servicios contratados y gremios, y habilítalos en la app movíl para que los usuarios de tus comunidades lo tengan </p>
                                </div>
                            </li>
                            <li class="mb-3 cp-li">
                                <div class="cp-li-card">
                                    <p class="cp-li-card-label">SOPORTE 24/7</p>
                                    <p class="cp-li-card-text">Tanto desde nuestro Panel Web de Administración como nuestra App Movíl</p>
                                </div>
                                
                            </li>
                        </ul>
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="col-md-6  ">
                                <a href="https://play.google.com/store/apps/details?id=developerteam.icomunity" target="_blank">
                                    <div class="cp-container-image">
                                        <img class="img-fluid cp-image" src="assets/img/playstore.png" alt="Play store image">
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6 ">
                                <a href="https://apps.apple.com/es/app/icomunity/id1325772871" target="_blank">
                                    <div class=" cp-container-image">
                                        <img class="img-fluid cp-image" src="assets/img/appstore.png" alt="App store image">
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                    <!--Grid column-->
                    <!--Grid column-->
                    <div class="col-md-6 col-xl-5 mt-xl-5 wow fadeInRight" data-wow-delay="0.3s">
                        <img src="assets/img/mobile1.png" alt="" class="img-fluid cp-image-header">
                    </div>
                    <!--Grid column-->
                </div>
                <!--Grid row-->
            </div>
            <!-- Content -->
        </div>
        <!-- Mask & flexbox options-->
    </div>
    <!-- Full Page Intro -->
</header>
<!-- Main navigation -->
<style>
    html,
    body {
        height: 100%;
    }
    
    .view,
    header {
        height: 200px;
    }
    
    @media (max-width: 740px) {
        html,
        body {
            height: 1000px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 850px) {
        html,
        body {
            height: 600px;
        }
    }
    
    .btn .fa {
        margin-left: 3px;
    }
    
    .top-nav-collapse {
        background-color: #424f95 !important;
    }
    
    .navbar:not(.top-nav-collapse) {
        background: transparent !important;
    }
    
    @media (max-width: 991px) {
        .navbar:not(.top-nav-collapse) {
            background: #424f95 !important;
        }
    }
    
    .btn-white {
        color: black !important;
    }
    
    h6 {
        line-height: 1.7;
    }
    
    .rgba-gradient {
        background: -moz-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: -webkit-gradient(linear, 45deg, from(rgba(42, 27, 161, 0.7)), to(rgba(29, 210, 177, 0.7)));
        background: -o-linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
        background: linear-gradient(to 45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
    }
</style>
<!-- Main navigation -->
<header>
    <app-section-nav [active]="'support'"></app-section-nav>
    <!-- Full Page Intro -->
    <div class="view" style="background-image: url('assets/img/bg-crop.png'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
        <!-- Mask & flexbox options-->
        <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
            <!-- Content -->
            <h1 class="h1-responsive white-text font-weight-bold mt-sm-5">Contactar con nuestro soporte</h1>
        </div>
    </div>
</header>

<section class="dark-grey-text">
    <div class="container z-depth-1 my-5 px-0">

        <!--Section: Content-->
        <section class="my-md-5" style="background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/People/12-col/img%20(97).jpg'); background-size: cover; background-position: center center;">

            <div class="rgba-black-strong rounded p-5">

                <!-- Section heading -->
                <h3 class="text-center font-weight-bold text-white mt-3 mb-5">¿Como te podemos ayudar?</h3>

                <div class="mx-md-5">

                    <div class="row">
                        <div class="col-md-6 mb-4">

                            <div class="card">
                                <div class="card-body px-4">
                                    <!-- Name -->
                                    <div class="md-form md-outline mt-0">
                                        <input type="text" id="name" class="form-control" placeholder="Nombre" [(ngModel)]="form.name">
                                    </div>
                                    <!-- Email -->
                                    <div class="md-form md-outline">
                                        <input type="text" id="email" class="form-control" placeholder="Email" [(ngModel)]="form.email">
                                    </div>
                                    <!-- Message -->
                                    <div class="md-form md-outline">
                                        <textarea id="message" class="md-textarea form-control" rows="3" placeholder="Mensaje" [(ngModel)]="form.message"></textarea>
                                    </div>

                                    <button (click)="submitEmail()" class="btn btn-primary btn-md btn-block ml-0 mb-0">Enviar</button>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 offset-md-1 mt-md-4 mb-4 white-text">

                            <h5 class="font-weight-bold">Te contestaremos en las proximas 24h</h5>
                            <p class="msg-success" *ngIf="contactSuccess">Mensaje correctamente enviado</p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    </div>
</section>

<app-section-footer></app-section-footer>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LegalComponent } from './pages/legal/legal.component';
import { ProComponent } from './pages/pro/pro.component';
import { SupportComponent } from './pages/support/support.component';


const routes: Routes = [
  {path: '',   redirectTo: '/home', pathMatch: 'full'},

  {path: 'home', component: HomeComponent},
  {path: 'legal', component: LegalComponent},
  {path: 'pro', component: ProComponent},
  {path: 'support', component: SupportComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
